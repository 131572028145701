import axios from "axios";

// //获取IP系列的产品列表
export function GetSeriesGoodsList(queryData) {
	return axios.get(`/w1/series/goods`, {
		params: queryData
	});
}


// //获取IP系列的产品列表
export function GetSeriesDisplayList(queryData) {
	return axios.get(`/w1/series/getDisplayImages`, {
		params: queryData
	});
}

// 获取陈列照相关联的商品
export function GetDisplayGoodsList(queryData) {
	return axios.get(`/w1/series/getDisplayGoods`, {
		params: queryData
	});
}

// 获取单个陈列照信息
export function GetDisplatInfo(queryData) {
	return axios.get(`/w1/series/getDisplayInfo`, {
		params: queryData
	});
}

// 一键加购陈列照相关联的所有商品
export function AllAddToCart(queryData) {
	return axios.get(`/w1/series/allAddToCart`,{
		params: queryData
	})
}


